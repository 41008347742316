<template>
    <v-container class="pa-2 ml-0 mr-0">
        <v-row v-if="activeSubTabCursosInternos !== 1">
            <v-col cols="2">
                <v-label>Fitrar por:</v-label>
            </v-col>
            <v-col cols="4">
                <v-select dense solo outlined flat hide-details :items="seleccionarListaFiltro()" item-text="text" item-value="value"
                    v-model="filtrar_por_seleccionado" />
            </v-col>
        </v-row>
        <v-row v-if="activeSubTabCursosInternos === 0 ">
            <v-col cols="2">
                <v-label>Grupo curso:</v-label>
            </v-col>
            <v-col cols="10">
                <v-autocomplete 
                    dense solo outlined flat hide-details clearable 
                    v-model="id_grupo_curso_seleccionado" 
                    :items="grupos_curso" 
                    item-key="id" 
                    item-text="nombre" 
                    item-value="id" 
                    prepend-inner-icon="search" 
                />
            </v-col>
        </v-row>
        <v-row v-if="activeSubTabCursosInternos !== 1">
            <v-col cols="2">
                <v-label>Nombre curso:</v-label>
            </v-col>
            <v-col cols="10">
                <v-autocomplete 
                    dense solo outlined flat  hide-details clearable 
                    v-model="id_curso_seleccionado" 
                    :items="cursos" 
                    item-key="id" 
                    item-text="nombre" 
                    item-value="id" 
                    prepend-inner-icon="search" 
                />
            </v-col>
        </v-row>
        <v-row v-if="activeSubTabCursosInternos !== 1">
            <v-col cols="2" v-if="filtrar_por_seleccionado === 'externos_fecha_vencimiento_hasta'">
                <v-label>Estado:</v-label>
            </v-col>
            <v-col cols="4" v-if="filtrar_por_seleccionado === 'externos_fecha_vencimiento_hasta'">
                <v-select dense solo outlined flat hide-details :items="filtro_estado_fecha_vencimiento" item-text="text" item-value="value"
                    v-model="filtro_estado_fecha_vencimiento_seleccionado" />
            </v-col>
            <v-col cols="2" v-if="filtrar_por_seleccionado === 'externos_fecha_realizacion_hasta'">
                <v-label>Hasta:</v-label>
            </v-col>
            <v-col cols="auto" v-if="filtrar_por_seleccionado === 'externos_fecha_realizacion_hasta'">
                <DatePickerText v-model="fecha_termino" dense flat />
            </v-col>

            <v-col cols="2" v-if="filtrar_por_seleccionado === 'externos_realizados_entre'">
                <v-label>Tipo fecha:</v-label>
            </v-col>
            <v-col cols="4" v-if="filtrar_por_seleccionado === 'externos_realizados_entre'">
                <v-select dense solo outlined flat hide-details :items="filtro_tipo_fecha" item-text="text" item-value="value"
                    v-model="filtro_tipo_fecha_seleccionada" />
            </v-col>
        </v-row>
        
        <v-row v-if="filtrar_por_seleccionado === 'externos_realizados_entre' || filtrar_por_seleccionado === 'internos_realizados_entre'">
            <v-col cols="2">
                <v-label>Inicio:</v-label>
            </v-col>
            <v-col cols="4">
                <DatePickerText v-model="fecha_inicio" dense flat />
            </v-col>
            <v-col cols="auto" class="mr-4">
                <v-label>Término:</v-label>
            </v-col>
            <v-col cols="4">
                <DatePickerText v-model="fecha_termino" dense flat />
            </v-col>
        </v-row>
        <v-divider class="my-3"/>
        <v-row justify="end">
            <v-col cols="auto" class="mr-0 pr-0" v-if="isData">
                <v-btn color="primary" class="mr-1" @click="generateReportePDF">
                    <v-icon left>picture_as_pdf</v-icon>
                    reporte pdf
                </v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn color="primary" class="mr-1" @click="filterItems">
                    <v-icon left>filter_list</v-icon>
                    buscar
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import DatePickerText from '@/shared/components/DatePickerText'
import { axiosHelper } from '@/helpers'
import moment from 'moment'
import 'moment/locale/es' // Importar el idioma español
import jsPDF from 'jspdf';
import 'jspdf-autotable';
moment.locale('es') // Establecer el idioma globalmente

export default {
    props: {
        activeTab: {
            type: Number,
            required: true
        },
        activeSubTabCursosInternos:{
            type: Number
        },
        isData: {
            type: Boolean,  
            default: false, 
        }
    },
    components: {
        DatePickerText
    },
    data: () => ({

        items:null,

        //
        tipo_curso: null, // puede ser curso interno o externo

        //
        fecha_inicio: null,
        fecha_termino: null,

        //  
        filtrar_por_cursos_externos: [
            { text: 'fecha de realizacíon', value: 'externos_fecha_realizacion_hasta' },
            { text: 'fecha de vencimiento', value: 'externos_fecha_vencimiento_hasta' },
            { text: 'Rango de fecha', value: 'externos_realizados_entre' }
        ],
        filtrar_por_cursos_internos: [
            { text: 'Cursos realizados a la fecha', value: 'internos_realizados_a_la_fecha' },
            { text: 'Cursos pendientes de realizar', value: 'internos_pendientes' },
            { text: 'Cursos realizados entre', value: 'internos_realizados_entre' }  
            // otros filtros que se pueden agregar  
        //  { text: 'Cursos certificado vigente', value: 'certificado_vigente' },
        //  { text: 'Cursos certificado vencido', value: 'certificado_vencido' }
        ],
        filtrar_por_seleccionado: '',

        // este filtro aplica solo para los cursos externos
        filtro_tipo_fecha:[
            { text: 'fecha de realizacíon', value: 'externos_realizados_entre' },
            { text: 'fecha de vencimiento', value: 'externos_vencidos_entre' },
        ],
        filtro_tipo_fecha_seleccionada:'',

        // este filtro aplica solo para los cursos externos
        filtro_estado_fecha_vencimiento:[
            { text: 'Cursos vencidos', value: 'externos_cursos_vencidos' },
            // { text: 'Cursos por vencer', value: 'externos_cursos_por_vencer' },
            { text: 'Cursos vigentes', value: 'externos_cursos_vigentes' }
        ],
        filtro_estado_fecha_vencimiento_seleccionado:'',
        //
        grupos_curso: [], // contiene los nombre de los grupo de acceso
        id_grupo_curso_seleccionado: null,
        //
        cursos: [],
        id_curso_seleccionado: null,
    }),
    watch: {
        activeTab(newValue) {
            if (newValue === 0) {
                this.fetchGrupoCursos();
                this.tipo_curso = "curso_interno";
                this.filtrar_por_seleccionado ='internos_realizados_a_la_fecha';
                this.cursos = null;

            }
            if (newValue === 1) {
                this.tipo_curso = "curso_externo";
                this.filtrar_por_seleccionado ='externos_realizados_entre';
                this.filtro_tipo_fecha_seleccionada = 'externos_realizados_entre';
                this.filtro_estado_fecha_vencimiento_seleccionado="externos_cursos_vencidos";
                this.cursos = [];
                this.fetchCursosExternos();
            }
        },
        id_grupo_curso_seleccionado(newGrupoCursoId) {
            this.$emit('clear-table');
            if (newGrupoCursoId) {
                this.fetchCursosPorGrupo(newGrupoCursoId);
            }
            if(newGrupoCursoId == null){
                this.id_curso_seleccionado = null;
            }
        },
        id_curso_seleccionado() {
            this.$emit('clear-table');
        },
        fecha_inicio() {
            this.$emit('clear-table');
        },
        fecha_termino() {
            this.$emit('clear-table');
        },
        filtrar_por_seleccionado() {
            this.$emit('clear-table');
        },
        filtro_tipo_fecha_seleccionada() {
            this.$emit('clear-table');
        },
        filtro_estado_fecha_vencimiento_seleccionado(){
            this.$emit('clear-table');
        },
    },
    created() {
        this.fecha_inicio = this.getFirstDayOfMonth();
        this.fecha_termino = this.getLastDayOfCurrentMonth();
        if (this.activeTab === 0) {
            this.fetchGrupoCursos();
            this.tipo_curso = "curso_interno";
            this.filtrar_por_seleccionado ='internos_realizados_a_la_fecha';
        }
        if (this.activeTab === 1) {
            this.fetchCursosExternos();
            this.tipo_curso = "curso_externo";
            this.filtrar_por_seleccionado ='externos_fecha_realizacion_hasta';
            this.filtro_tipo_fecha_seleccionada = 'externos_realizados_entre';
            this.filtro_estado_fecha_vencimiento_seleccionado="externos_cursos_vencidos";
        }
    },
    methods: {
        async obtenerGrupoDelParticipante(participanteId){
            //
            try {
                const response = await axiosHelper.get('edu/grupos/grupos_participante/?participanteId='+participanteId);
                this.grupos_acceso_participante = [{ id: null, nombre: 'Seleccione un grupo...' }, ...response.data];
                this.grupos_acceso_participante_seleccionado = null;
            } catch (error) {
                console.error("Error al intentar recuperar los grupo de acceso del participante:", error);
            }
        },
        getFirstDayOfMonth() {
            const date = new Date();
            date.setDate(1); // Primer día del mes
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Añadir cero delante si es necesario
            const day = String(date.getDate()).padStart(2, '0'); // Añadir cero delante si es necesario
            return `${year}-${month}-${day}`;
        },
        getCurrentDate() {
            const date = new Date();
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Añadir cero delante si es necesario
            const day = String(date.getDate()).padStart(2, '0'); // Añadir cero delante si es necesario
            return `${year}-${month}-${day}`;
        },
        getLastDayOfCurrentMonth() {
            const date = new Date();
            const year = date.getFullYear();
            const month = date.getMonth() + 1; // Obtener el mes actual (de 0 a 11) y sumar 1 para obtener el mes correcto

            // Crear una nueva fecha con el primer día del mes siguiente
            const firstDayNextMonth = new Date(year, month, 1);

            // Restar un día para obtener el último día del mes actual
            const lastDayCurrentMonth = new Date(firstDayNextMonth - 1);

            const lastDay = String(lastDayCurrentMonth.getDate()).padStart(2, '0');
            const lastMonth = String(lastDayCurrentMonth.getMonth() + 1).padStart(2, '0');
            const lastYear = lastDayCurrentMonth.getFullYear();

            return `${lastYear}-${lastMonth}-${lastDay}`;
        },
        async fetchGrupoCursos() {
            try {
                const response = await axiosHelper.get('edu/grupos/nombres');
                this.grupos_curso = [{ id: null, nombre: 'Seleccione ...' }, ...response.data];
                this.id_grupo_curso_seleccionado = null;

            } catch (error) {
                console.error("Error fetching group names:", error);
            }
        },
        async fetchCursosPorGrupo(grupoCursoId) {
            try {
                const response = await axiosHelper.get(`edu/cursos/nombres/?grupo_curso_id=${grupoCursoId}`);
                if (response.data.length >= 1) {
                    this.cursos = [{ id: null, nombre: 'Seleccione...' }, ...response.data];
                    this.id_curso_seleccionado = null;
                    this.cursos_participante_seleccionado=null;
                }
            } catch (error) {
                console.error("Error fetching courses:", error);
            }
        },
        async fetchCursosExternos() {
            try {
                const response = await axiosHelper.get('edu/cursos-externos/?nombres_cursos_externos=true');
                this.cursos = [{ id: -1, nombre: 'Todos los cursos' }, ...response.data];
                this.id_curso_seleccionado = -1;
            } catch (error) {
                console.error("Error fetching external courses:", error);
            }
        },
        async filterItems() {
            var tipo = this.activeSubTabCursosInternos;
            const params = {
                tipo_reporte:'reporte_cursos_internos',
                subtipo_reporte:'estado_curso',
                id_grupo_curso_seleccionado: this.id_grupo_curso_seleccionado,
                id_curso_seleccionado: this.id_curso_seleccionado,
                fecha_inicio: this.fecha_inicio,
                fecha_termino: this.fecha_termino,
                filtrar_por_seleccionado: this.filtrar_por_seleccionado,
                filtro_tipo_fecha_seleccionada: this.filtro_tipo_fecha_seleccionada,
                filtro_estado_fecha_vencimiento_seleccionado: this.filtro_estado_fecha_vencimiento_seleccionado
            };
            try {
                
                var url = this.tipo_curso === 'curso_interno' ? 'edu/cursos-reportes' : 'edu/cursos-externos/reporte';
                const response = await axiosHelper.get(url, null, params);
                this.items = response.data;
                this.$emit('datos-recibidos', this.items);
            } catch (error) {
                console.error('Error:', error);
            }  
        },
        seleccionarListaFiltro(){
            return this.tipo_curso === 'curso_interno' ? this.filtrar_por_cursos_internos : this.filtrar_por_cursos_externos;
        },
        generateCertificadoPDF(){
            console.log("Generando el cetificado en base al reporte");
        },
        formatDate(date) {
            return moment(date).format('D MMMM YYYY');
        },
        loadImage(url) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.crossOrigin = "Anonymous"; // Permite el acceso a imágenes de otro dominio (si es necesario)
                img.src = url;
        
                img.onload = () => {
                    resolve(img); // Resuelve la promesa con el HTMLImageElement cargado
                };
        
                img.onerror = (error) => {
                    reject(new Error("Error al cargar la imagen: " + error));
                };
            });
        },
        async addHeader(empresa_id, doc, margin) {
            try {
                const pageCount = doc.internal.getNumberOfPages();
                const response = await axiosHelper.get("edu/logos/", null, { empresa: empresa_id });
                
                const logoUrl = process.env.VUE_APP_API_URL + response.data.archivo.substring(1);
                console.log("logoUrl: ", logoUrl);
                const logoImage = await this.loadImage(logoUrl);
                for (let i = 1; i <= pageCount; i++) {
                    console.log("agregando logo: ", logoImage);
                    console.log(margin + 150);
                    console.log(margin / 2);
                    console.log(20);
                    console.log(20);
                    doc.setPage(i);
                    doc.addImage(logoImage, 'JPEG', margin + 150, margin / 2, 20, 10);
                }
            } catch (error) {
                console.error("Error al cargar el logo:", error);
            }
        },
        async generateReportePDF() {
            const anchoLinea = { maxWidth: 170 };
            var items = this.items;
            const filtros = items.filtros;
            console.log('filtros: ',  items.filtros);
            const doc = new jsPDF();
            const margin = 20;
            const headerHeight = 5; // Altura del encabezado
            const fechaActual = new Date();
            const pageWidth = doc.internal.pageSize.getWidth();
            const pageHeight = doc.internal.pageSize.getHeight();

            const addFooter = () => {
                const pageCount = doc.internal.getNumberOfPages();
                for (let i = 1; i <= pageCount; i++) {
                    doc.setPage(i);
                    doc.setFontSize(10);
                    doc.setFont('helvetica', 'normal');
                    const text = `${fechaActual} Página ${i} de ${pageCount}`;
                    const textWidth = doc.getTextWidth(text);
                    doc.text(text, pageWidth - margin - textWidth, pageHeight - margin / 2);
                }
            };

            const startContentY = margin + headerHeight;

            // Título principal
            doc.setFontSize(22);
            doc.setFont('helvetica', 'bold');
            const tituloY = startContentY; // Posición Y del título considerando el margen y el encabezado
            let titulo = "";
            if(filtros.tipo_reporte === 'curso_interno'){
                titulo = doc.splitTextToSize("Reporte Cursos Internos", pageWidth - margin * 2);
            } else {
                titulo = doc.splitTextToSize("Reporte Cursos Externos", pageWidth - margin * 2);
            }
            doc.text(titulo, margin, tituloY,anchoLinea);
            let startY = tituloY + (titulo.length * 10);
            doc.setFontSize(12);
            doc.setFont("times", "bold");
            doc.text("Tipo Reporte:", margin, startY);
            doc.setFont("times", "italic");
            const desplazamiento = 27;
            if(filtros.filtrar_por === 'internos_realizados_a_la_fecha'){
                const texto = "Cursos internos realizados a la fecha."
                doc.text(texto,margin + desplazamiento,startY);
            } else if (filtros.filtrar_por === 'internos_pendientes'){
                const texto = "Cursos internos pendientes de realizar."
                doc.text(texto,margin + desplazamiento,startY);
            } else if (filtros.filtrar_por === 'internos_realizados_entre'){
                const texto = "Cursos internos realizados entre el " + this.formatDate(filtros.fecha_inicio) + " y el " + this.formatDate(filtros.fecha_termino);
                doc.text(texto,margin + desplazamiento,startY);
            } else if (filtros.filtrar_por === 'externos_fecha_realizacion_hasta'){
                const texto = "Cursos externos realizados hasta el " + this.formatDate(new Date());
                doc.text(texto,margin + desplazamiento,startY);
            }
            else if (filtros.filtrar_por === 'externos_cursos_vencidos'){
                const texto = "Cursos externos vencidos"
                doc.text(texto,margin + desplazamiento,startY);
            }
            else if (filtros.filtrar_por === 'externos_realizados_entre'){
                const texto = "Cursos externos realizados entre el "+ this.formatDate(filtros.fecha_inicio) + " y el " + this.formatDate(filtros.fecha_termino);
                doc.text(texto,margin + desplazamiento,startY);
            }
            startY +=6;
            if(filtros.tipo_reporte === 'curso_interno'){
                doc.setFont("times", "bold");
                doc.text("Grupo De Acceso:", margin, startY);
                doc.setFont("times", "italic");
                doc.text(filtros.grupo_curso, margin+35, startY,anchoLinea);
                startY += 6;
            } 
            const cursosString = filtros.cursos.join(", ").toLowerCase();
            const lineHeight = 6;  // Establece una altura de línea base (ajústala según tu tamaño de fuente)
            doc.setFont("times", "bold");
            doc.text("Cursos:", margin, startY);
            doc.setFont("times", "italic");
            const lines = doc.splitTextToSize(cursosString, 160);
            doc.text(lines, margin + 20, startY);
            startY += lineHeight * lines.length;  // Incrementa `startY` basado en el número de líneas
            doc.setLineWidth(0.1);
            doc.line(margin, startY, pageWidth - margin, startY); // Coordenadas (x1, y1, x2, y2)
            startY += 6;
            items.data.forEach(item => {
                const cursoNombre = doc.splitTextToSize(item.nombre_curso.toLowerCase(), pageWidth - margin * 2);

                doc.setFontSize(14);
                doc.setFont('helvetica', 'bold');
                doc.text(cursoNombre, margin, startY);

                if(item.participantes.length > 0) {
                    startY += cursoNombre.length * 5;

                    const body = item.participantes.map(participante => [
                        participante.nombre,
                        participante.apellido,
                        participante.rut,
                        participante.cargo,
                        new Date(participante.fecha_realizacion).toLocaleDateString(),
                        new Date(participante.fecha_vencimiento).toLocaleDateString()
                    ]);

                    doc.autoTable({
                        head: [['Nombre', 'Apellido', 'Rut', 'Cargo', 'Fecha Realización', 'Fecha Vencimiento']],
                        body: body,
                        startY: startY + 2,
                        margin: { top: startContentY, left: margin, right: margin }
                    });

                    startY = doc.lastAutoTable.finalY + 10;
                } else {
                    doc.setFont('times', 'normal');
                    doc.setFontSize(12);
                    doc.text("Sin resultados para este tipo de reportes", margin, startY + 5);
                    startY += 20;
                }
            });
            console.log("items.data[0].empresa_id: ", items.data[0].empresa_id);
            await this.addHeader(items.data[0].empresa_id,doc,margin);
            addFooter();
            doc.save('reporte-filtrado.pdf');
        }
    }
}
</script>

