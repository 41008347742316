<template>
    <v-container class="pa-2 ml-0 mr-0">
        <v-row>
            <v-col cols="2">
                <v-label class="text-body-1">Tipo de reporte:</v-label>
            </v-col>
            <v-col cols="4">
                <v-select dense solo outlined flat hide-details :items="tipo_rendimiento" item-text="text" item-value="value"
                    v-model="tipo_rendimiento_seleccionado" />
            </v-col>
            <v-col cols="2" v-if="tipo_rendimiento_seleccionado === 'rendimiento_curso'">
                <v-label>Empresa:</v-label> 
            </v-col>
            <v-col cols="4" v-if="tipo_rendimiento_seleccionado === 'rendimiento_curso'">
                <v-autocomplete
                    dense solo outlined flat  hide-details clearable 
                    v-model="id_empresa_seleccionada" 
                    :items="empresas" 
                    item-key="id" 
                    item-text="nombre" 
                    item-value="id" 
                    prepend-inner-icon="search" 
                />
            </v-col>
        </v-row>
        <v-row v-if="tipo_rendimiento_seleccionado === 'rendimiento_participante'">
            <v-col cols="2">
                <v-label class="text-body-1">Participante:</v-label>
            </v-col>
            <v-col cols="4">
                <v-autocomplete
                    dense solo outlined flat  hide-details clearable 
                    v-model="participante_seleccionado" 
                    :items="participantes" 
                    item-key="id" 
                    item-text="full_nombre" 
                    item-value="rut" 
                    prepend-inner-icon="search"
                    @change="getRendimientoParticipante"
                />
            </v-col>
        </v-row>
        <v-row v-if="tipo_rendimiento_seleccionado === 'rendimiento_curso'">
            <v-col cols="2">
                <v-label>Curso:</v-label>
            </v-col>
            <v-col cols="10">
                <v-autocomplete
                    dense solo outlined flat  hide-details clearable 
                    v-model="curso_list_id_selecionado" 
                    :items="cursos" 
                    item-key="id" 
                    item-text="nombre" 
                    item-value="id" 
                    prepend-inner-icon="search" 
                /> 
            </v-col>
        </v-row>
        <v-row justify="end">
            <v-col cols="auto" v-if="isData">
                <v-btn color="primary" class="mr-0" @click="generateReportePDF">
                    <v-icon left>file_download</v-icon>
                    {{ tipo_rendimiento_seleccionado === 'rendimiento_participante' ? 'reporte participante' : 'reporte pdf' }}
                </v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn color="primary" class="mr-1" @click="getRendimientoCurso">
                    <v-icon left>filter_list</v-icon>
                    buscar
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { axiosHelper, DocPdfRendimientoCursoInternoIndividual, DocPdfRendimientoCursoResumen } from '@/helpers'
export default{
    props: {
        activeSubTabCursosInternos:{
            type: Number
        },
        isData: {
            type: Boolean,  
            default: false, 
        }
    },
    data:()=>({
        //
        tipo_rendimiento:[
            { text: 'Rendimiento De Curso', value: 'rendimiento_curso' },
            { text: 'Rendimiento De Participante', value: 'rendimiento_participante' }
        ],
        //
        data:{},
        //
        tipo_rendimiento_seleccionado:null,
        //
        participantes:[],
        participante_seleccionado:null,
        //
        grupos_curso:[],
        id_grupo_curso_seleccionado:null,
        //
        cursos: [],
        curso_list_id_selecionado: null,
        //
        id_empresa_seleccionada:null,
        empresas:[],
        rendimiento_participantes_curso:[]
    }),
    created(){
        this.tipo_rendimiento_seleccionado='rendimiento_curso';
        this.fetchGrupoCursos();
    },
    mounted() {
        this.getParticipantes();
        this.getEmpresas();
    },
    watch:{
        id_empresa_seleccionada(newEmpresaId){
            if(newEmpresaId){
                this.fetchCursosList(newEmpresaId);
            }
            if(newEmpresaId == null){
                this.$emit('clear-table');
                this.id_grupo_curso_seleccionado = null;
                this.curso_list_id_selecionado = null;
            }
        },
        id_grupo_curso_seleccionado(newGrupoCursoId) {
            this.$emit('filter-changed');
            if (newGrupoCursoId) {
                this.fetchCursosPorGrupo(newGrupoCursoId);
            }
            if(newGrupoCursoId == null){
                this.$emit('clear-table');
                this.curso_list_id_selecionado = null;
            }
        },
        curso_list_id_selecionado(idSeleccionado){
            console.log("curso_list_id_selecionado(idSeleccionado) = ", idSeleccionado);
            if (idSeleccionado){
                this.curso_list_id_selecionado = idSeleccionado;
            }
            if(idSeleccionado == null){
                this.$emit('clear-table');
            }
        },
        participante_seleccionado(participanteId){
            if(participanteId == null){
                this.$emit('clear-table');
            }
        },
        tipo_rendimiento_seleccionado(nuevoTipoRendimiento, anteriorTipoRendimiento){
            if(nuevoTipoRendimiento !== anteriorTipoRendimiento){
                this.participante_seleccionado = null;
                this.curso_list_id_selecionado = null;
                this.id_grupo_curso_seleccionado = null;
                this.$emit('clear-table');
            }
        }
    },
    methods:{
        async fetchCursosPorGrupo(grupoCursoId) {
            try {
                console.log("fetchCursosPorGrupo(grupoCursoId) = ", grupoCursoId);
                const response = await axiosHelper.get(`edu/cursos/nombres/?grupo_curso_id=${grupoCursoId}`);
                if (response.data.length === 1) {
                    this.cursos = response.data;
                    this.curso_list_id_selecionado = this.cursos[0].id;

                } else {
                    this.cursos = [{ id: null, nombre: 'Seleccione...' }, ...response.data];
                    this.curso_list_id_selecionado = null;
                }
            } catch (error) {
                console.error("Error fetching courses:", error);
            }
        },
        async fetchGrupoCursos() {
            try {
                const response = await axiosHelper.get('edu/grupos/nombres');
                this.grupos_curso = [{ id: null, nombre: 'Seleccione ...' }, ...response.data];
                this.id_grupo_curso_seleccionado = null;

            } catch (error) {
                console.error("Error fetching group names:", error);
            }
        },
        async getParticipantes(){
            try {

                const response = await axiosHelper.get('edu/usuarios/participantes');
                this.participantes = [{ id: null, full_nombre: 'Seleccione un participante...' }, ...response.data];
                if (this.participantes.length > 0){
                    this.participante_seleccionado = this.participantes[0];
                }
            } catch (error) {
                console.error("Error al obtener los participantes:", error);
            }
        },
        async getRendimientoCurso(){
            try{
                if(this.curso_list_id_selecionado == 1){
                    this.curso_list_id_selecionado = 0;
                }
                const objeto_curso= this.cursos[this.curso_list_id_selecionado];
                console.log("getRendimientoCurso()", objeto_curso);
                const params = {
                    'tipo_reporte':'reporte_cursos_internos',
                    'subtipo_reporte':'rendimiento_curso',
                    'grupo_id':objeto_curso.grupo_id,
                    'curso_id':objeto_curso.curso_id
                }
                const response = await axiosHelper.get('edu/cursos-reportes', null,params);
                this.data = {
                    'tipo_reporte':'rendimiento_curso',
                    'data':response.data
                }
                this.$emit('datos-recibidos', this.data);
            }catch(error){
                console.error("Error al obtener el rendimiento del curso:", error);
            }
        },
        async getRendimientoParticipante(rut){
            try{
                const params ={rut}
                const response = await axiosHelper.post('edu/stats/type/?d=persona', params);
                let rendimiento_participante = [];
                for(let grupo of response.data.rendimiento.logs){
                    for(let registro of grupo.registros){
                        const evaluacion = {
                            id_evaluacion:registro.evaluacion.id,
                            nombre:registro.evaluacion.nombre,
                            nombre_curso:registro.evaluacion.curso,
                            nombre_grupo:grupo.grupo,
                            cantidad_preguntas: registro.respuestas.length,
                            total_desaciertos:registro.total_incorrectas,
                            duracion:registro.duracion.split(".")[0] + " (hh:mm:ss)"
                        };
                        const respuestas = [];
                        for(let respuesta of registro.respuestas){
                            respuestas.push(respuesta);
                        }
                        evaluacion.respuestas = respuestas;
                        rendimiento_participante.push(evaluacion);
                    }
                }
                this.data = {
                    'tipo_reporte':'rendimiento_persona',
                    'data': response.data,
                    'rendimiento_participante_table':rendimiento_participante
                }
                this.$emit('datos-recibidos', this.data);
            }catch(error){
                console.error("Error al obtener el rendimiento del participante:", error);
            }
        },
        generateReportePDF(){
            const tipoReporte = this.data.tipo_reporte;
            switch (tipoReporte) {
                case 'rendimiento_persona':
                    const doc = new DocPdfRendimientoCursoInternoIndividual();
                    doc.generarPdf("Reporte de rendimiento por participante", this.data.data);
                    break;
                case 'rendimiento_curso':
                    const doc2 = new DocPdfRendimientoCursoResumen();
                    doc2.generarPdf("Reporte de rendimiento por curso",this.data.data);
                    break;
                default:
                    console.warn("Tipo de reporte desconocido:", tipoReporte);
                    break;
            }
        },
        async getEmpresas(){
            try {
                const response = await axiosHelper.get('empresas');
                this.empresas = [{ id: null, nombre: 'Seleccione una empresa...' }, ...response.data];
                this.id_empresa_seleccionada = null;
            } catch (error) {
                console.error("Error al obtener las empresas:", error);
            }
        },
        async fetchCursosList(newEmpresaId ) {
            try {
                if(newEmpresaId == null){
                    return;
                }
                const response = await axiosHelper.get(`edu/cursos/por_empresa/?empresa_id=${newEmpresaId}`);
                if(response.data.length === 0){
                    this.cursos = [{ id: null, nombre: 'No hay cursos disponibles' }];
                    this.curso_list_id_selecionado = null;
                } else if(response.data.length === 1) {
                    this.cursos = response.data;
                    this.curso_list_id_selecionado = this.cursos[0].id;
                } else {
                    this.cursos = [{ id: null, nombre: 'Seleccione...' }, ...response.data];
                    this.curso_list_id_selecionado = null;
                }
            } catch (error) {
                console.error("Error fetching courses:", error);
            }
        }
    }
}
</script>